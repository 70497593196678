.container-footer{
    height: 17.8%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.parent-footer{
    width: 100%;
    height: 100%;
    max-width: 1920px;
}

.parent-footer img{
    max-width: 100%;
    height: 130px;
}

@media only screen and (max-width: 1110px){
    .parent-footer{
        display: flex;
        align-items: flex-end;
        
    }
}