
/* flex as display, it expands the items to film available space or shrink the items to prevent overflow. */
.container-profile {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 10%;
    min-height: fit-content;
}

 
.parent-profile {
    display: flex;
    align-items: center;
    color: aliceblue;
}

 
.info-profile-name{
    font-size: 24px;
    font-family: "Poppins SemiBold";
}
.profile-info-role{
    display: flex;
    flex-direction: column;
    margin: 14px 0 24px 0;
}
.profile-info-role h1{
 font-size: 40px;
 font-family: cursive;
}

.primary-text{
    color: (--white);
}

.highlight-text {
    color: #CC3636
} 


.role-line-profile{
    font-size: 19px;
    margin: 5px 0 0;
    font-family: "Poppins Light";
}

.options .highlighted-btn{
    margin: 0 0 0 28px;
}
.options .highlighted-btn:hover{
    margin: 0 0 0 28px;
    background-color: cornsilk;
    color: blue;
}

.profile-picture{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0px 0px var(--white);
    border-radius: 50%;
    height: 380px;
    width: 380px;
    margin: 0 0px 0 100px;
}

.profile-picture-background{
    height: 92%;
    width: 92%;
    background-image: url("../../assets/Home/TrevonProfilePicture.jpg");
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.profile-picture-background:hover{
 transform: scale(1.07);
 transition:  1s ease-out;
}

.colz .colz-icon i{
    color: white;
    padding: 8px;
    padding-left: 0px;
}

.colz .colz-icon i:hover{
    transform: scale(1.5);
}
.colz .colz-icon span{
    color: white;
    padding: 8px;
    padding-left: 0px;
}

.colz .colz-icon span:hover{
    transform: scale(1.5);
}

/* Moblie responsive for iPad Pro */
@media screen and (max-width: 1110px){
    .profile-picture{
        margin: 0 0px 0 80px;
    }
    .info-profile-name{
        font-size: 20px;
    }

    .profile-info-role .primary-text{
        font-size: 12px;
    }
    .options .btn{
        width: 120px;
        padding: 11px 0;
        z-index: 700;
    } 

}
@media screen and (max-width: 910px){
    .profile-picture{
        height: 320px;
        width: 320px;
        margin: 0 0px 0 4px;
    }
}

@media screen and (max-width: 910px){
    .profile-picture{
        margin: 0 !important;
    }
    .parent-profile{
        flex-direction: column-reverse;
        margin: 40px 0 0 0;
    }
    .options{
    display: flex;
    justify-content: space-between;
    }
    .info-profile{
    margin: 25px 0 0;
    }
   .options .highlighted-btn{
    margin: 0 !important;
   }
   .options .profile-info-role{
    text-align: center;
   }
}

@media screen and (max-width: 375px){
    .profile-picture{
        height: 275px;
        width: 275px;
        margin-top: -90px;
    }
    .info-profile{
        width: 90%
        }
}