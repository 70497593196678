.contact {
    display: grid;
    margin-top: 8rem;
}

.contact-container {
    /* width: var(--container-width-lg);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem; */
    width: 58%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact-option {
width: 120%;
background: #CC3636;;
padding: 1.2em;
border-radius: 1.2rem;
text-align: center;
border: 1px solid transparent;
transition: var(--transition);
}

.contact-option:hover {
    background: #CFD2CF;
    /* border-color: ; */
}

.contact-option a {
    text-decoration: none;
    color: whitesmoke;
}
.contact-header {
    text-align: center;
    color: var(--white);
}
/* .email-header {
   
} */

/****form area*****/

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid black;
    resize: none;
    color:whitesmoke;
}

input::placeholder, textarea::placeholder {
    color: white;
}