.aboutme-container{
    display: grid;
    margin-top: 8rem;
}

.parent-aboutme {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.container-image-aboutme{ 
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid; 
    place-items: center;
}

.image-aboutme{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    height: 85%;
    width: 85%;
}

/* .image-aboutme img{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    height: 50%;
    width: 50%;
} */

.image-aboutme:hover{
    transform: rotate(0);
}

.aboutme-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.aboutme-card{
    /* background: var(--color-bg-variant); */
    color: var(--white);
    background-color: #CC3636;
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.aboutme-card:hover{
    color: white;
    background-color: #CFD2CF;
    margin: 0 0 0 28px;
    /* border-color: var(--color-primary-variant); */
    cursor: default;
}

.aboutme-card h5{
    font-size: 0.95rem;
}

.aboutme-card small {
  font-size: 0.7rem;
  /* color: var(--white);; */
}

.aboutme-cards-container p {
  margin: 2rem 0 2.6rem;
  color: var(--white);
}





.container {
    width: var(--container-width-lg);
    margin: 0 auto;
  }
  
  h1, h2, h3, h4, h5 {
    font-weight: 500;
  }
  
  /* .aboutme-container{
    margin-top: 8rem;
  } */
  
  .aboutme-container h2 {
    text-align: center;
    color: var(--white);
  }
  
  .aboutme-container h2 {
    margin-bottom: 3rem;
  }

  p {
    color: var(--white);
  }

  a {
    color: var(--color-primary);
    transition: var(--transition);
  }
  
  a:hover {
    color: var(--color-white);
  }
  
  /* .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
  } */
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  .btn-primary {
    background-color: #1f2235;
    border:2px solid linen;
    /* color: var(--color-bg); */
  }

  .btn-primary:hover{
    background-color: #1f2235;
    border: 2px solid #ff5823;
  }
  