:root{
  --light-blue : #04C3FF;
  --light-blue-shadow:  rgb(69 211 255 / 27%);
  --sea-green: #0ABEAD;
  --sea-green-dark: #0CBFAE;
  --sea-green-extra-dark: #09BEAD;
  --sea-green-shadow: rgba(11, 190, 173,0.4);
  --header-hamburger-background : rgba(11, 190, 173,0.8);
  --orange: #F57F17;
  --dark-orange: #FF5823;
  --orange-shadow: rgba(245, 127, 23,0.2);
  --white: #ffffff;
  --black: #333333; 
  --disabled-btn: #09bead7a;
  --error-message:  #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow:  #9c9c9ce3;
  --toast-success: #0CBFAE;
  --loader-color: #0CBFAE;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-primary: #4db5ff;
--color-bg: #1f1f38;
--color-white: #fff;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

