.portfolio {
    display: grid;
    margin-top: 8rem;
}


.portfolio-container {
    width: var(--container-width-lg);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio-item {
  background-color: #CC3636;
    padding: 1rem; /*padding is causing the buttons to be stacked on each other */ 
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio-item:hover {
   background-color: #CFD2CF;
}

.portfolio-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio-item h3 {
    margin: 1.2rem 0 2rem;
}


h2 {
    text-align: center;
    color: var(--white);
    margin-bottom: 3rem;
}

.btn-port {
    color: var(--white);
  border: 2px solid linen;
  font-size: 12px;
  background-color: #1f2235;
  border-radius: 50px;
  padding: 14px 0;
  width: 150px;
  cursor: pointer;
  transition: 0.2s;
  font-family: "Poppins SemiBold";
}

.btn-port:active {
    transform: translateY(2px);
  }

  .btn-port:hover {
    color: aliceblue; 
  border: 2px solid #ff5823;
  background-color: #1f2235;
  }

  .port-header {
    text-align: center;
    color: var(--white);
  }

/* .portfolio-image img {
    height: 45%;
    width: 45%;
} */


/* .btn:hover{
    margin: 0 0 0 28px;
    background-color: cornsilk;
    color: blue;
}  */